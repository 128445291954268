.bg-yellow {
    background-color: #F9AB21;
}

.bg-black {
    background-color: #0E0E0E;
    color: #FFF;
}

.bg-blue {
    background-color: #3E67B1;
    color: #FFF;
}

.pos-relative {
    position: relative !important;
}

.pos-absolute {
    position: absolute !important;
}

.signatureText {
    font-family: "Parisienne", cursive;
    font-weight: 400;
    font-style: normal;
    font-size: 40px !important;
}

.panelTitle {
    font-family: "Koulen", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.bannerLine1 {
    font-family: "Koulen", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 0px 2px 3px #00000083;
}

.bannerLine2 {
    font-family: "Koulen", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 0px 2px 3px #00000083;
}

.bannerSetting {
    padding-top: 8%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 8%;
    color: #FFF;
}

.panel2Setting {
    padding-left: 12%;
    padding-right: 12%;
}

.panel3Setting {
    padding-left: 18%;
    padding-right: 18%;
}

.panel4Setting {
    padding-left: 5%;
    padding-right: 5%;
}

.mapSetting {
    z-index: 999;
    width: 400px;
    height: 590px;
    left: 58%;
    top: 5%;
}

.panel2Card {
    border: solid 2px #FFF !important;
    background-color: rgba(255, 255, 255, 0.3) !important;
    border-radius: 15px !important;
}

.btn-blue {
    background-color: #3E67B1 !important;
    color: #FFF !important;
}

.contactCardText a {
    color: #DD5471 !important;
}

.tourCard {
    border: solid 2px #FFF !important;
    background-color: transparent !important;
    padding-top: 3%;
    min-height: 500px;
    border-radius: 26px !important;
}

.tourTitle {
    font-size: 2em;
    margin-top: 4%;
}

.tourCard:nth-of-type(1) {
    background-image: url("https://deih43ym53wif.cloudfront.net/large_fisabilillah-barelang-bridge-batam-indonesia-shutterstock_1482544481.jpg_2064957538.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.tourCard:nth-of-type(2) {
    background-image: url("https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.indonesia.travel%2Fsg%2Fen%2Fdestinations%2Fsumatra%2Fbatam&psig=AOvVaw2HVkJrc0Elxo6EP9HO7Qtj&ust=1708267649894000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCIDPtYXPsoQDFQAAAAAdAAAAABAR");
    background-repeat: no-repeat;
    background-size: cover;
}

.tourCard:nth-of-type(3) {
    background-image: url("https://thesmartlocal.com/wp-content/uploads/2022/10/Ranoh-Island-2.jpg");
    background-repeat: no-repeat;
    background-size: fill;
}

.custom-ul {
    list-style-type: none !important;
}

.sticky-button {
    position: fixed !important;
    z-index: 9999 !important;
    background-color: #08901E !important;
    color: #fff !important;
    border-radius: 10px;
    cursor: pointer;
}

.sticky-button:hover {
    position: fixed;
    z-index: 9999;
    background-color: #067c1a !important;
    color: #fff !important;
    border-radius: 10px;
    cursor: pointer;
}

.footerSitelinkNav {
    text-decoration: none;
    color: #000;
}

.footerContact {
    color: #DD5471;
}

.btn-whatsapp {
    background-color: #08901E !important;
    color: #FFF !important;
}

/* FONT STYLING */

.montserratSubrayada400 {
    font-family: "Montserrat Subrayada", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.montserrat600 {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.koulen400 {
    font-family: "Koulen", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.inter400 {
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-style: normal;
}

@media (min-width:992px) {
    .bannerLine1 {
        font-size: 40px !important;
    }

    .bannerLine2 {
        font-size: 56px !important;
    }

    .panelTitle {
        font-size: 40px !important;
    }

    .tourPricing {
        font-size: 1.6em;
    }

    /* Sticky Button Styling */
    .sticky-button {
        bottom: 20px;
        right: 20px;
        padding: 10px;
        width: 14% !important;
    }

    .sticky-button:hover {
        bottom: 20px;
        right: 20px;
        padding: 10px;
    }

    .sticky-button i {
        font-size: 20px !important;
    }

    .sticky-button p {
        font-size: 9px !important;
    }

    .sticky-button h3 {
        font-size: 20px !important;
    }

    .baseDivSetting {
        padding-top: 3% !important;
        padding-bottom: 3% !important;
    }

    .panel1Setting {
        padding-left: 15% !important;
        padding-right: 15% !important;
    }

    .panel5Setting {
        padding-left: 5% !important;
        padding-right: 5% !important;
    }

    .logoBottom {
        width: 8% !important;
    }

    .smContact {
        display: none !important;
    }

    #lgContact {
        display: inline-flex !important;
    }
}

@media (min-width:300px) {
    .bannerLine1 {
        font-size: 14px;
    }

    .bannerLine2 {
        font-size: 17px;
    }

    .panelTitle {
        font-size: 30px;
    }

    /* Sticky Button Styling */
    .sticky-button {
        bottom: 20px;
        right: 20px;
        padding: 10px;
        width: 40%;
    }

    .sticky-button:hover {
        bottom: 20px;
        right: 20px;
        padding: 10px;
    }

    .sticky-button i {
        font-size: 14px;
    }

    .sticky-button p {
        font-size: 7px;
    }

    .sticky-button h3 {
        font-size: 12px;
    }

    .baseDivSetting {
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .panel1Setting {
        padding-left: 8%;
        padding-right: 8%;
    }

    .panel5Setting {
        padding-left: 5%;
        padding-right: 5%;
    }

    #about p {
        font-size: 15px;
    }

    .logoBottom {
        width: 25%;
    }

    .smContact {
        display: block;
    }

    #lgContact {
        display: none;
    }
}