.vehicleImg {
    width: 100%;
}

.carCard {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0.43) 100%) !important;
    border: solid 0.5px #FFF !important;
}

.carTitle {
    text-shadow: 0px 2px 3px #00000083;
}

@media (min-width:992px) {

    #cardRow #cardContainer:nth-child(1) .carCard {
        border-radius: 12px 0px 0px 12px !important;
    }

    #cardRow #cardContainer:nth-child(2) .carCard {
        border-radius: 0px 0px 0px 0px !important;
    }

    #cardRow #cardContainer:last-child .carCard {
        border-radius: 0px 12px 12px 0px !important;
    }

    #cardRow:nth-child(n+1) {
        margin-top: 3%;
    }

    .carTitle {
        font-size: 26px;
    }
}

@media (min-width:300px) {

    #cardRow #cardContainer:nth-child(1) .carCard {
        border-radius: 12px 12px 12px 12px;
    }

    #cardRow #cardContainer:nth-child(2) .carCard {
        border-radius: 12px 12px 12px 12px;
    }

    #cardRow #cardContainer:last-child .carCard {
        border-radius: 12px 12px 12px 12px;
    }

    #cardRow:nth-child(n+1) {
        margin-top: 3%;
    }

    .carTitle {
        font-size: 26px;
    }
}